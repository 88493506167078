<!--
 * @Author: Libra
 * @Date: 2022-05-09 15:10:25
 * @LastEditTime: 2023-11-10 15:39:52
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/confirm/layout/index.vue
-->
<template>
    <div class="layout">
      <div class="main-container">
        <div class="container">
          <div class="left">
            <el-steps direction="vertical" :active="active">
              <el-step :title="item.title" v-for="(item, index) in stepsList" :key="index"></el-step>
            </el-steps>
          </div>
          <div class="right">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'StepsLayout',
  data() {
    return {
      active: 0,
      stepsList: [],
      steps: [
        {
          title: '设备检测',
          hidden: !this.$store.state.examInfo.isDeviceCheck,
          routeName: 'Device'
        },
        {
          title: '完善信息',
          hidden: false,
          routeName: 'ConfirmBasic'
        },
        {
          title: this.$store.state.examInfo.isFaceRecognizeNeed ? '身份验证' : '摄像拍照',
          hidden: this.$store.state.examInfo.cameraMonitorType === 0,
          routeName: this.$store.state.examInfo.isFaceRecognizeNeed ? 'FaceRecognition' : 'ConfirmCamera'
        },
        {
          title: '手机监控',
          hidden: this.$store.state.examInfo.mobileMonitorType === 0 && this.$store.state.examInfo.mobileVideoType === 0,
          routeName: 'PhoneMonitor'
        },
        {
          title: '考试须知',
          hidden: false,
          routeName: 'ConfirmNotice'
        },
        {
          title: this.$store.state.isPractice ? '考前练习' : '开始考试',
          hidden: false,
          routeName: 'ConfirmExam'
        }
      ]
    }
  },
  mounted() {
    this.stepsCom()
  },
  methods: {
    stepsCom() {
      this.stepsList = this.steps.filter(item => !item.hidden)
      this.active = this.stepsList.findIndex(item => {
        if (this.$route.name === 'ConfirmCamera' && item.routeName === 'FaceRecognition') {
          item.title = '摄像拍照'
          return item.routeName === 'FaceRecognition'
        }
        return item.routeName === this.$route.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
@import './index.scss';
</style>
