var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout" }, [
    _c("div", { staticClass: "main-container" }, [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-steps",
              { attrs: { direction: "vertical", active: _vm.active } },
              _vm._l(_vm.stepsList, function (item, index) {
                return _c("el-step", {
                  key: index,
                  attrs: { title: item.title },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "right" }, [_vm._t("content")], 2),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }